import { useEffect } from "react";

export const useDevConfig = () => {
    const accessToken = "";
    const userID = 2;
    const companyID = 392;
    const clientID = 1;
    const username = 'admin';
    const adminYN = true;
    const administratorYN = false;
    const language = 'en';

    useEffect(() => {
        const devCookies = {
            accessToken,
            userID,
            companyID,
            clientID,
            username,
            adminYN,
            administratorYN,
            language
        }

        window.postMessage(devCookies, "*");
    }, [adminYN, administratorYN])
}
