// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../components/web-font/poppins/Poppins-Medium.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../components/web-font/poppins/Poppins-SemiBold.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../components/web-font/poppins/Poppins-Regular.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"PoppinsMedium\";\n  src: local(\"PoppinsMedium\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  font-weight: normal;\n}\n@font-face {\n  font-family: \"PoppinsSemiBold\";\n  src: local(\"PoppinsSemiBold\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n  font-weight: normal;\n}\n@font-face {\n  font-family: \"PoppinsRegular\";\n  src: local(\"PoppinsRegular\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n  font-weight: normal;\n}", "",{"version":3,"sources":["webpack://src/themes/customized/font.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,uFAAA;EACA,mBAAA;AACJ;AAEA;EACI,8BAAA;EACA,yFAAA;EACA,mBAAA;AAAJ;AAGA;EACI,6BAAA;EACA,wFAAA;EACA,mBAAA;AADJ","sourcesContent":["@font-face {\r\n    font-family: \"PoppinsMedium\";\r\n    src: local(\"PoppinsMedium\"), url(\"../../components/web-font/poppins/Poppins-Medium.ttf\") format(\"truetype\");\r\n    font-weight: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: \"PoppinsSemiBold\";\r\n    src: local(\"PoppinsSemiBold\"), url(\"../../components/web-font/poppins/Poppins-SemiBold.ttf\") format(\"truetype\");\r\n    font-weight: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: \"PoppinsRegular\";\r\n    src: local(\"PoppinsRegular\"), url(\"../../components/web-font/poppins/Poppins-Regular.ttf\") format(\"truetype\");\r\n    font-weight: normal;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
